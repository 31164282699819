<template>
  <div v-loading="loading" class="order-detail-container">
    <div class="cont">
      <div class="button-back">
        <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
          <img src="../../../assets/back.png" alt="">
        </el-button>
      </div>
      <br />
      <h4>基本信息</h4>
      <table class="table-cont">
        <tbody>
          <tr>
            <td class="details-div-span">
              交易状态：
              <span class="detail-span1">{{ orderDetail.jd_cancel_status == 1 ? "取消中" : orderDetail.trade_status_text
                }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                {{ orderDetail.hangup_status === 1 ? '(店铺挂起)' : (orderDetail.hangup_status === 2 ? '(企业挂起)' : (
    orderDetail.hangup_status === 3 ? '(供应商挂起)' : '')) }}
              </span>
            </td>
          </tr>
          <!--        下单人信息-->
          <tr>
            <td>下单人信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人姓名：{{ orderDetail.member_name }}</td>
            <td>下单人手机号：{{ orderDetail.mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人所属分组：{{ orderDetail.group_name }}</td>
            <td v-if="[5, 7].includes(orderDetail.mall_type)">下单人账号：{{ orderDetail.login_account || '' }}</td>
          </tr>
          <!--        订单信息-->
          <template>
            <tr>
              <td>订单信息：</td>
            </tr>
            <tr class="detail-tr1">
              <td>用户下单时间：{{ orderDetail.create_time | unixToDate }}</td>
              <td>用户支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
            </tr>
            <tr class="detail-tr1">
              <td v-if="orderDetail.mall_type === 7">用户支付方式：
                <span>站点账户余额垫付</span>
              </td>
              <td v-else>用户支付方式：
                <span v-if="orderDetail.trade_kind == 3">商品售后换新</span>
                <span v-else-if="orderDetail.trade_kind == 2">售后更换商品</span>
                <span v-else-if="orderDetail.trade_kind == 4">补单</span>
                <span v-else>{{ orderDetail.payment_method_name }}</span>
              </td>
              <td style="display: flex;">
                <span>
                  用户订单总价：{{ orderDetail.account_pay_money + orderDetail.weixin_pay_money +
    orderDetail.discount_card_coupon_price + orderDetail.discount_card_voucher_price | unitPrice("¥") }}
                </span>
                <p>
                  <span v-if="orderDetail.shop_combo_id">
                    (商品: 0 + 站点运费: {{ orderDetail.shop_freight_price | unitPrice("¥") }})
                  </span>
                  <span v-else>
                    (商品: {{ orderDetail.shop_goods_price | unitPrice("¥") }} + 站点运费: {{ orderDetail.shop_freight_price |
    unitPrice("¥") }})
                  </span>
                  <br>
                  (优惠券抵扣金额：{{ orderDetail.discount_card_coupon_price | unitPrice("¥") }} +
                  提货券支付金额：{{ orderDetail.discount_card_voucher_price | unitPrice("¥") }} +
                  账户支付金额：{{ orderDetail.account_pay_money | unitPrice("¥") }} +
                  微信支付金额：{{ orderDetail.weixin_pay_money | unitPrice("¥") }})
                </p>
              </td>
            </tr>
            <tr class="detail-tr1">
              <td>商城下单时间：{{ orderDetail.payment_time | unixToDate }}</td>
              <td>商城支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
            </tr>
            <tr class="detail-tr1">
              <td>商城支付方式：站点账户余额</td>
              <td>
                商城支付金额：
                <span v-if="orderDetail.delivery_method == 1">{{ orderDetail.enterprise_total_price | unitPrice("¥") }}
                  (商品: {{ orderDetail.enterprise_goods_price | unitPrice("¥") }} + 企业运费: {{
    orderDetail.enterprise_freight_price | unitPrice("¥") }})</span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr class="detail-tr1">
              <td>企业支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
              <td>
                <span>
                  企业支付金额：{{ orderDetail.platform_total_price | unitPrice("¥") }}
                  (商品: {{ orderDetail.platform_price | unitPrice("¥") }} + 平台运费: {{ orderDetail.platform_freight_price |
    unitPrice("¥") }})
                </span>
              </td>
            </tr>
          </template>
          <!--     收货人信息   -->
          <tr>
            <td>{{ orderDetail.delivery_method == 1 ? '收货人信息：' : '领取人信息：' }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>{{ orderDetail.delivery_method == 1 ? '收货人：' : '领取人：' }}{{ orderDetail.consignee_name }}</td>
            <td>{{ orderDetail.delivery_method == 1 ? '收货人手机号：' : '领取人手机号：' }}{{ orderDetail.consignee_mobile }}</td>
          </tr>
          <tr class="detail-tr1" v-if="orderDetail.delivery_method == 1">
            <td>
              收货地址：
              {{ orderDetail.consignee_province }}{{ orderDetail.consignee_city }}
              {{ orderDetail.consignee_county }}{{ orderDetail.consignee_town ? orderDetail.consignee_town : '' }}
              {{ orderDetail.consignee_address }}
            </td>
          </tr>
          <!--        订单来源信息-->
          <tr v-if="orderDetail.shop_combo_id || orderDetail.delivery_method !== 1 || orderDetail.mall_type == 7">
            <td>备注信息</td>
          </tr>
          <tr v-if="orderDetail.shop_combo_id || orderDetail.delivery_method !== 1 || orderDetail.mall_type == 7"
            class="detail-tr1" style="display:inline-block; max-width: 30vw">
            <td v-if="orderDetail.order[0].remark">
              {{ orderDetail.order[0].remark }}
            </td>
            <td v-else>无</td>
          </tr>
          <!--        订单来源信息-->
          <tr>
            <td>订单来源信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>来源应用：
              <span v-if="orderDetail.mall_type == 2">现金商城</span>
              <span v-else-if="orderDetail.mall_type == 3">套餐卡商城</span>
              <span v-else-if="orderDetail.mall_type == 4">AI智能套餐卡商城</span>
              <span v-else-if="orderDetail.mall_type == 5">双选商城</span>
              <span v-else-if="orderDetail.mall_type == 7">一件代发商城</span>
              <span v-else-if="orderDetail.mall_type == 6">小程序商城</span>
            </td>
            <td>商城名称：{{ orderDetail.mall_name }}</td>
          </tr>
          <tr v-if="orderDetail.shop_combo_name && orderDetail.mall_type !== 7">
            <td>礼包信息</td>
          </tr>
          <tr v-if="orderDetail.shop_combo_name && orderDetail.mall_type !== 7" class="detail-tr1">
            <td>礼包名称：{{ orderDetail.shop_combo_name }}</td>
          </tr>
          <tr v-if="cardInfoTh.card_type == 6 || cardInfoTh.card_type == 7">
            <td>卡券信息</td>
          </tr>
          <tr v-if="cardInfoTh.card_type == 6 || cardInfoTh.card_type == 7" class="detail-tr1">
            <td>卡券名称：{{ cardInfoTh.card_name }}</td>
            <td>卡券类型：{{ cardInfoTh.card_type == 6 ? '计次卡' : '宅配卡' }}</td>
            <td>卡号：{{ cardInfoTh.card_code }}</td>
            <td>卡券面值：{{ cardInfoTh.card_value }}</td>
          </tr>
          <tr v-if="cardInfoTh.card_type == 6 || cardInfoTh.card_type == 7" class="detail-tr1">
            <td v-if="cardInfoTh.card_type == 7">卡券配送周期：{{ cardInfoTh.card_kind | filterKind }} {{
    `(${cardInfoTh.available_times}次配送)` }}</td>
            <td>卡号所属公司：{{ cardInfoTh.company }}</td>
            <td>{{ cardInfoTh.card_type == 6 ? '兑换礼包名' : '配送礼包名称' }}：{{ cardInfoTh.sho_combo_name }}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <!--产品列表-->
    <el-row v-if="productList" :gutter="0">
      <el-col :span="24">
        <div class="d-header">商品列表</div>
        <div class="my-table-out" :style="{ maxHeight: tableMaxHeight + 'px' }">
          <table class="my-table">
            <thead>
              <tr class="bg-order">
                <th class="shoplist-header">
                  <span>商品</span>
                  <span>单价/数量</span>
                </th>
                <th>维权状态</th>
                <th>物流状态</th>
                <th>物流单号</th>
                <th>供货方</th>
                <th>订单状态</th>
                <th>用户账号</th>
              </tr>
            </thead>
            <tbody v-for="item in productList" :key="item.trade_id">
              <tr style="width: 100%; height: 10px"></tr>
              <tr class="bg-order">
                <td class="shoplist-content-out" :colspan="6">
                  子订单编号：{{ item.sn }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span v-if="item.ext_order_id">
                    京东单号：{{ item.ext_order_id }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  下单时间: {{ item.create_time | unixToDate }}
                </td>
                <td>
                  <el-button type="text" @click="handleOperateOrder(item)">查看详情</el-button>
                </td>
              </tr>
              <tr>
                <!--商品-->
                <td>
                  <p v-for="(shop, index) in item.sku_list" :key="index" class="shoplist-content">
                    <span class="goods-info">
                      <span style="position: relative">
                        <img :src="shop.goods_image" alt class="goods-image" />
                        <img v-if="shop.goods_source" class="goods-tag" :src="getRowTagImage(shop.goods_source)">
                      </span>
                      <el-tooltip class="item" effect="light" :content="shop.name" placement="top">
                        <el-button class="shop-name" type="text">
                          <!-- {{ shop.name.replaceAll('amp;', '').replaceAll('', ' & nbsp; ') }} -->
                          {{ shop.name }}
                        </el-button>
                      </el-tooltip>
                    </span>
                    <span class="shop-num">
                      <template>
                        <span v-if="shop.goods_type == 'NORMAL'">
                          <span>{{ shop.original_price | unitPrice("￥") }}</span>
                          ×
                          <span class="goods—price">{{ shop.num }}</span>
                        </span>
                        <span v-else>
                          {{ shop.revise_exchange_money | unitPrice("￥") }} +
                          {{ shop.revise_exchange_point }}积分 ×
                          <span class="goods—price">{{ shop.num }}</span>
                        </span>
                      </template>
                    </span>
                  </p>
                </td>
                <!-- 维权状态 -->
                <td>
                  <p style="height: 71px" v-for="stat in item.sku_list" :key="stat.sku_id" class="shoplist-content">
                    {{ fService_status(stat.service_status) }}
                  </p>
                </td>
                <!-- 物流状态 -->
                <td>
                  <p style="height: 71px" v-for="stat in item.sku_list" :key="stat.sku_id" class="shoplist-content">
                    <span v-if="stat.logistics_status_text">{{ stat.logistics_status_text }}</span>
                    <span v-else>{{ stat.state === 0 ? '未发货' : (stat.state === 2 ? '已发货' : '已收货') }}</span>
                  </p>
                </td>
                <!-- 物流单号 -->
                <td>
                  <p style="height: 71px" v-for="stat in item.sku_list" :key="stat.sku_id" class="shoplist-content">
                    <span v-if="stat.delivery_json">
                      {{ stat.delivery_json[0].logiName }}:{{ stat.delivery_json[0].deliveryNo }}
                    </span>
                  </p>
                </td>
                <!-- 供货方 -->
                <td>
                  {{ item.order_source === 0 ? '平台' : (item.order_source === 1 ? '京东' : '自有') }}
                  <!--                  <p-->
                  <!--                    style="height: 71px"-->
                  <!--                    v-for="stat in item.sku_list"-->
                  <!--                    :key="stat.sku_id"-->
                  <!--                    class="shoplist-content"-->
                  <!--                  >-->
                  <!--                    {{ stat.order_source === 0 ? '平台': (stat.order_source === 1 ? '京东': '自有') }}-->
                  <!--                  </p>-->
                </td>
                <!-- 订单状态 -->
                <td>{{ item.order_status_text }}</td>
                <!-- 用户账号 -->
                <td>{{ item.member_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>
    <!--商品列表-->
    <el-row v-if="orderDetail && orderDetail.gift_list && orderDetail.gift_list.length" :gutter="0">
      <el-col :span="24">
        <div class="d-header">赠品列表</div>
        <el-table :data="orderDetail.gift_list" :header-cell-style="{ textAlign: 'center' }">
          <el-table-column label="赠品图片">
            <template slot-scope="scope">
              <img :src="scope.row.gift_img" class="goods-image" />
            </template>
          </el-table-column>
          <el-table-column label="赠品名称">
            <template slot-scope="scope">
              <a :href="scope.row.gift_img" class="goods-name" target="_blank">{{
    scope.row.gift_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</a>
            </template>
          </el-table-column>
          <el-table-column label="赠品价格">
            <template slot-scope="scope">￥{{ scope.row.gift_price | unitPrice }}</template>
          </el-table-column>
          <el-table-column label />
          <el-table-column label />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import { Foundation } from "@/../ui-utils";
import TagJD from '@/assets/goods/tag-jd.png';
import TagJX from '@/assets/goods/tag-jx.png';
import TagZY from '@/assets/goods/tag-zy.png';

export default {
  name: "orderDetail",
  data () {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 订单日志loading状态 */
      loading_log: false,
      /** 订单详情数据 */
      orderDetail: '',
      /**卡券详情 */
      cardInfoTh: '',
      /** 订单日志 */
      orderLog: [],
      /** 订单sn */
      sn: this.$route.params.sn,
      /** 基本信息、发票信息、买家信息、商家信息 */
      orderInfo: [],
      /** 产品列表 */
      productList: [],
      tableMaxHeight: document.body.clientHeight - 54 - 34 - 50 - 15,
    };
  },
  filters: {
    paymentTypeFilter (val) {
      return val === "online" ? "在线支付" : "线下支付";
    },
    unixToDate (val) {
      return Foundation.unixToDate(val);
    },
    filterKind (data) {
      switch (data) {
        case 1:
          return '月卡'
        case 2:
          return '季卡'
        case 3:
          return '年卡'
        case 4:
          return '自定义'
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.sn = to.params.sn;
    next();
  },
  activated () {
    this.sn = this.$route.params.sn;
    this.GET_OrderDetail();
  },
  mounted () {
    sessionStorage.setItem('orderListActiveName', '0')
    this.GET_OrderDetail();
  },
  methods: {
    GET_OrderDetail () {
      this.loading = true;
      this.GET_OrderLog();
      API_order.getTradeDetail(this.sn).then((res) => {
        this.loading = false;
        this.orderDetail = res;
        this.orderDetail['payment_method_name'] = this.orderDetail.payment_method_name.replace(/,/g, '+')
        this.productList = res.order;
        this.productList.forEach(item => {
          item.sku_list.forEach(i => {
            i.delivery_json = JSON.parse(i.delivery_json)
          })
        })
        this.countShowData();
        //查询卡券信息
        res.card_code && this.getOrdergetCardInfoTh(res)
      }).catch(() => {
        this.loading = false;
      });
    },
    /**查询订单详情卡券信息 */
    getOrdergetCardInfoTh (params) {
      API_order.getOrdergetCardInfoTh({ card_code: params.card_code, shop_id: params.shop_id }).then((res) => {
        this.cardInfoTh = res;
      })
    },
    /** 获取订单日志 */
    GET_OrderLog () {
      this.loading_log = true;
      API_order.getOrderLog(this.sn).then((res) => {
        this.loading_log = false;
        this.orderLog = res;
      }).catch(() => {
        this.loading_log = false;
      });
    },

    /** 查看、操作订单 */
    handleOperateOrder (item) {
      console.log(item)
      this.$router.push({ path: `/order/order_detail/${item.sn}` });
    },

    /** 确认收款 */
    confirmPay () {
      this.$confirm("确定要确认收款吗？", "提示", { type: "warning" })
        .then(() => {
          API_order.confirmPay(this.sn, this.orderDetail.shop_order_price).then(
            (res) => {
              this.$message.success("订单确认收款成功！");
              this.$route.params.callback();
              this.GET_OrderDetail();
            }
          );
        })
        .catch(() => { });
    },

    /** 取消订单 */
    cancelOrder () {
      this.$confirm("确定要取消这个订单吗？", "提示", { type: "warning" })
        .then(() => {
          API_order.cancleOrder(this.sn).then(() => {
            this.$message.success("订单取消成功！");
            this.$route.params.callback();
            this.GET_OrderDetail();
          });
        })
        .catch(() => { });
    },

    /** 组合基本信息、发票信息、买家信息、商家信息 */
    countShowData () {
      const o = this.orderDetail;
      const f = Foundation;
      const promotions = [];
      promotions.push({
        label: "运&ensp;&ensp;&ensp;&ensp;费",
        value: "￥" + f.formatPrice(o.shipping_price),
      });
      this.orderInfo = [
        [
          {
            title: '交易状态',
            key: 'order_status',
            items: [
              {
                label: '交易状态', value: (o.trade_status_text + (o.hangup_status === 1 ? '(店铺挂起)' :
                  (o.hangup_status === 2 ? '(企业挂起)' : (o.hangup_status === 3 ? '(供应商挂起)' : ''))))
              }
            ]
          },
          {
            title: '下单人信息',
            key: 'next_person',
            items: [
              { label: '下单人手机号', value: o.mobile },
              { label: '下单人姓名', value: o.nickname },
              { label: '下单人所属分组', value: o.group_name }
            ]
          }
        ],
        [
          {
            title: "订单信息",
            key: "base",
            items: [
              { label: "用户下单时间", value: f.unixToDate(o.create_time) },
              { label: "用户支付时间", value: f.unixToDate(o.payment_time) },
              { label: "商城支付时间", value: f.unixToDate(o.payment_time) },
              { label: "企业支付时间", value: f.unixToDate(o.payment_time) },
              { label: "用户支付金额", value: o.shop_combo_id ? `${f.formatPrice(o.shop_total_price)} (商品: 0 + 站点运费: ${o.shop_freight_price})` : `${f.formatPrice(o.shop_total_price)} (商品: ${o.shop_goods_price}+ 站点运费: ${o.shop_freight_price})` },
              { label: "企业支付金额", value: o.platform_total_price ? `${f.formatPrice(o.platform_total_price)} (商品: ${o.platform_price} + 平台运费: ${o.platform_freight_price})` : `${f.formatPrice(o.platform_price + o.platform_freight_price)} (历史订单)` },
              { label: "商城支付金额", value: `${f.formatPrice(o.enterprise_goods_price + o.enterprise_freight_price)} (商品: ${o.enterprise_goods_price} + 企业运费: ${o.enterprise_freight_price})` },
              { label: "用户支付方式", value: (o.payment_method_name ? o.payment_method_name : "") }
            ],
          },
          {
            title: '收货人信息',
            key: 'ship_info',
            items: [
              { label: '收货人:', value: o.consignee_name },
              { label: '收货人手机号:', value: o.consignee_mobile },
              { label: '收货地址:', value: o.consignee_province + o.consignee_city + o.consignee_county + o.consignee_town + o.consignee_address }
            ]
          }
        ],
        [
          {
            title: '备注信息',
            key: 'remark_info',
            items: [
              { label: '', value: o.order[0].remark ? o.order[0].remark : '无' },
            ]
          },
          {
            title: 1
          }
        ],
        [
          {
            title: '订单来源',
            key: 'order_source',
            items: [
              { label: '来源应用', value: o.shop_combo_id ? '套餐卡商城' : o.source },
              { label: '商城名称', value: o.mall_name },
            ]
          },
          {
            title: 1
          }
        ]
      ];
      if (o.shop_combo_id) {
        this.orderInfo[1][0].items.splice(4, 1)
        this.orderInfo[1][0].items.push({
          label: "卡号",
          value: o.card_code
        })
      } else {
        this.orderInfo.splice(2, 1)
      }
    },

    fService_status (status) {
      switch (status) {
        case "NOT_APPLY":
          return "未申请";
        case "APPLY":
          return "已申请";
        case "PASS":
          return "审核通过";
        case "REFUSE":
          return "审核未通过";
        case "EXPIRED":
          return "已失效或不允许申请售后";
        case "REFUNDFAIL":
          return "退款失败";
        case "COMPLETED":
          return "完成";
        case "WAIT_SEND_BACK":
          return "待寄回";
        case "SEND_BACK":
          return "买家寄回";
        case "SELLER_SHIP":
          return "卖家发货";
        case "REFUSE_SHIP":
          return "卖家拒绝发货";
        default:
          return "";
      }
    },
    getRowTagImage (goods_source) {
      switch (goods_source) {
        case '3':  // 自有商品
          return TagZY;
        case '1':  // 京东商品
          return TagJD;
        case '0':  // 普通商品（精选）
          return TagJX;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-detail-container {
  background-color: #fff;
  padding: 20px 20px;
}

.d-header {
  padding: 10px 0 10px 20px;
  font-size: 14px;
  color: #333;
  background-color: #f5f7fa;
  border-bottom: 1px solid #e1e8ed;

  &h2 {
    font-size: 14px;
    font-weight: 400;
  }
}

.item {
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 10px 0 10px 20px;
  line-height: 24px;
  white-space: nowrap;

  & .item-label {
    float: left;
    margin-right: 20px;
    font-size: 14px;
    color: #999;
  }

  & .item-value {
    overflow: hidden;
    white-space: normal;
    word-break: break-all;
    font-size: 14px;
    color: #666;
  }
}

::v-deep .el-table td:not(.is-left) {
  text-align: center;
}

.goods-image {
  width: 50px;
  height: 50px;
}

.goods-name {
  color: #4183c4;

  &:hover {
    color: #f42424;
  }
}

.sku-spec {
  color: #ff9800;
  margin: 0;
}

.sku-act-tags {
  padding: 0;
  margin: 0;
}

.sku-act-tag {
  display: inline-block;
  padding: 0 5px;
  line-height: 15px;
  margin-right: 5px;
  border: 1px solid #f42424;
  color: #f42424;
}

/*表格信息*/
.my-table-out {
  white-space: nowrap;
  overflow-y: scroll;
  text-overflow: ellipsis;
  width: 100%;
  max-height: 800px;
}

.my-table {
  width: 100%;
  margin-bottom: 40px;
  background: #fff;
  border-collapse: collapse;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;

  .bg-order {
    background: #fafafa;
  }

  thead {
    th {
      padding: 10px 0;
      border: 1px solid #ebeef5;
      border-collapse: collapse;
      color: #909399;
      text-align: center;
    }

    th.shoplist-header {
      padding: 10px 20px 10px 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  tbody {
    margin-top: 10px;

    td {
      width: 300px;
      border: 1px solid #ebeef5;
      border-collapse: collapse;
      text-align: center;
      padding: 0;
    }

    td:first-child {
      text-align: left;
    }

    td:not(:first-child) {
      padding: 3px;
    }

    td.shoplist-content-out {
      padding-left: 20px;
    }

    /*商品信息*/
    p.shoplist-content:not(:last-child) {
      border-bottom: 1px solid #ebeef5;
      border-collapse: collapse;
    }

    p.shoplist-content {
      margin: 0;
      padding: 0 20px;
      box-sizing: padding-box;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .goods-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;

        img {
          display: block;
          margin-right: 10px;
        }

        a {
          display: block;
          color: #409eff;
        }

        .shop-name {
          max-width: 10vw;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
          color: #000;
        }
      }
    }

    div.order-money {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      span {
        display: inline-block;
        padding: 5px;
      }

      span.order-amount {
        color: red;
      }
    }
  }

  /* 商品图片 */
  .goods-image {
    width: 50px;
    height: 50px;
  }

  /** 商品价格 */
  .goods—price {
    // margin-right: 50px;
  }
}

.el-row {
  clear: both;
}

.button-back {
  padding: 10px 10px;
  float: right;

  .back_btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #1A43A9;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.goods-tag {
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
